.ant-picker-time-panel-column {
  overflow-y: scroll;
}

.ant-picker-now {
  display: none !important;
}

.ant-picker-time-panel-cell-selected {
  .ant-picker-time-panel-cell-inner {
    background: #f3ebff !important;
  }
}

.ant-btn-primary {
  background: #933697;
  color: white;
  border-color: #933697;

  &:hover {
    background: #933697;
    color: white;
    border-color: #933697;
  }
}
