@import '~intl-tel-input/build/css/intlTelInput.min.css';

.iti {
  width: 100%;
}

.iti__flag {
  background-image: url("~intl-tel-input/build/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("~intl-tel-input/build/img/flags@2x.png");
  }
}
